/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 18번째 로그에서는 .dev 도메인, 랭킹 시각화, 개발자 설문 조사에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bluedesigns.com/products/yeti/"
  }, "Blue - Yeti")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sony.co.kr/electronics/voice-recorders/icd-tx650"
  }, "TX650 디지털 보이스 레코더 TX 시리즈 | ICD-TX650 | Sony KR")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bluedesigns.com/products/yeti-nano/"
  }, "Blue - Yeti Nano")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/dot-dev-doamin-for-developers"
  }, "개발자라면 탐나는 .dev 최상위 도메인 등록 시작 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kr.godaddy.com/tlds/dev-domain"
  }, ".DEV 도메인 이름 | .DEV 도메인 등록 - GoDaddy KR")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/.com"
  }, ".com - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/.io"
  }, ".io - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://get.dev/"
  }, "Google Registry - .dev")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Jon_Postel"
  }, "Jon Postel - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://commons.wikimedia.org/wiki/File:Jon_Postel_%28full_frame%29.jpg"
  }, "The TLD map with Jon Postel Wikimedia Commons")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.icann.org/"
  }, "ICANN - Internet Corporation for Assigned Names and Numbers")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=BQovQUga0VE"
  }, "Top 15 Best Global Brands Ranking (2000-2018) - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.interbrand.com/best-brands/best-global-brands/methodology/"
  }, "Best Global Brands - Interbrand")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=wykaDgXoajc"
  }, "Top 10 Country GDP Ranking History (1960-2017) - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ted.com/talks/hans_rosling_shows_the_best_stats_you_ve_ever_seen?language=ko"
  }, "Hans Rosling: 한스 로슬링이 이제껏 보지 못했던 최고의 통계를 보여준다. | TED Talk")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://video.twimg.com/ext_tw_video/1099900512336576512/pu/vid/1280x720/WyQK0y_78CMfvEjl.mp4"
  }, "주요 연예기획사 시가총액 순위 변화")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/carmatrocity/status/1098538649908666368"
  }, "Carmen Hernández Andoh on Twitter: \"A bunch of talented, amazing people from Travis will be looking for jobs soon. ... /1\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.idera.com/"
  }, "IDERA | SQL Server, Database Management, and IT Performance Tools")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2019/01/23/idera-acquires-travis-ci/"
  }, "Idera acquires Travis CI | TechCrunch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.reddit.com/r/devops/comments/at3oyq/it_looks_like_ibera_is_gutting_travis_ci_just_a/"
  }, "It looks like Ibera is gutting Travis CI just a few weeks after acquiring it : devops")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.travis-ci.com/2019-01-23-travis-ci-joins-idera-inc"
  }, "The Travis CI Blog: Travis CI joins the Idera family")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bitrise.io/"
  }, "Bitrise - Mobile Continuous Integration and Delivery")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://circleci.com/"
  }, "Continuous Integration and Delivery - CircleCI")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.gitlab.com/product/continuous-integration/"
  }, "GitLab Continuous Integration & Delivery | GitLab")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://programmers.co.kr/pages/dev-survey-2019"
  }, "프로그래머스 2019 개발자 설문조사 리포트 | 프로그래머스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.jetbrains.com/research/python-developers-survey-2018/"
  }, "Python Developers Survey 2018 Results")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.jetbrains.com/idea/download/#section=mac"
  }, "Download IntelliJ IDEA: The Java IDE for Professional Developers by JetBrains")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.jetbrains.com/student/"
  }, "For Students: Free Professional Developer Tools by JetBrains")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://macromates.com/"
  }, "TextMate: Text editor for macOS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/editing-multiline-command-on-shell"
  }, "Bash, Zsh에서 여러줄의 명령어를 에디터로 편집하고 실행하기 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=T5ZR5wOu82M"
  }, "셸에서 여러줄의 명령어를 에디터로 편집하고 실행하기 /w VSCode - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/rds/aurora/?nc1=h_ls"
  }, "데이터 베이스 관리 시스템 | MySQL | Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.pythonanywhere.com/"
  }, "Host, run, and code Python in the cloud: PythonAnywhere")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
